<template>
    <el-dialog 
        :visible.sync="previewVisible" 
        :title="data && data.name || '视频播放'" 
        center 
        append-to-body 
        :close-on-click-modal="false" 
        @close="tcPlayer && tcPlayer.destroy()" 
        id="play-box"
        width="600px">
        <div class="preview-box" v-loading="loading">
            <div class="video-container" id="video-container"></div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data(){
        return{
            previewVisible:false,
            data:null,
            loading: false,
            tcPlayer: null
        }
    },
    methods:{
        show(data){
            this.data = data;
            this.previewVisible = true
            this.play();
        },
        play(){
            this.loading = true;
            this.$request({
                url: "/video/play",
                method: "POST",
                params: {
                    videoId: this.data.id
                },
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.playLoad( result );
                    } else {
                        this.$message.error(msg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        playLoad(url){
            const that = this	
            this.previewVisible = true
            this.$nextTick(() => {
                this.$utils.loadScript(
                    {
                        id:'tcplayer',
                        url:'//imgcache.qq.com/open/qcloud/video/vcplayer/TcPlayer-2.3.2.js'
                    },() =>{
                        this.$nextTick(() => {
                            // const _height = document.getElementById("play-box").offsetWidth * 0.3
                            that.tcPlayer = new TcPlayer('video-container', {
                                "m3u8": url, 
                                "autoplay" : true,      //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的                           
                                "width" :  '100%',//视频的显示宽度，请尽量使用视频分辨率宽度
                                "height" : '100%'
                            });
                        })
                    }
                );
            })
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
    max-width: 100%;
}
.preview-box{
    text-align: center;
    height: calc(100vw * 0.3);
    .video-container{
        width: 100%;
        height: 100%;
        video{
            // width: 100%;
            // height: 100%;
            vertical-align: middle;
        }
    }
}
</style>