<template>
    <div>
        <tab name="4"></tab>
        <div class="page-content-box">
            <el-table
                :data="dataList"
                style="width: 100%"
                v-loading="listLoading"
                size="small"
                header-row-class-name="page-list-table"
            >
                <el-table-column prop="pic" label="视频封面">
                    <template slot-scope="scope">
                        <el-image v-if="scope.row.pic" :src="scope.row.pic | coverImage(400,225)" fit="cover" style="max-width:100%" :preview-src-list="[scope.row.pic]"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="视频名称" min-width="120" show-overflow-tooltip></el-table-column>
                <el-table-column prop="type" label="类型">
                    <template slot-scope="scope">{{videoType.get(scope.row.type)}}</template>
                </el-table-column>
                <el-table-column prop="time" label="时长"></el-table-column>
                <el-table-column prop="clickNum" label="播放次数"></el-table-column>
                <el-table-column prop="playNum" label="半播放次数"></el-table-column>
                <el-table-column prop="createTime" label="上传时间" min-width="150"></el-table-column>
                <el-table-column prop="display" label="状态">
                    <template slot-scope="scope">
                        <span v-if="scope.row.display == 1" class="color-success">显示</span>
                        <span v-else class="color-info">隐藏</span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="play(scope.row)"
                            >播放</el-button
                        >
                        <el-button
                            v-if="scope.row.display != 1"
                            type="text"
                            size="small"
                            @click="$refs.videoEdit.show(scope.row.id)"
                            >编辑</el-button
                        >
                    </template>
                </el-table-column>    
            </el-table>
            <div  class="page-pagination-box clearfix MT20">
                <div class="fl">
                    <el-button type="primary" size="small" @click="$refs.videoEdit.show()">上传视频</el-button>
                    <el-button type="primary" size="small" @click="$refs.videoTask.show()">待转码视频</el-button>
                </div>
                <el-pagination
                    v-if="data"
                    class="fr"
                    @size-change="handleSizeChange"
                    @current-change="getDataList"
                    :current-page.sync="params.pageNo"
                    :page-sizes="[15, 30, 50]"
                    :page-size.sync="params.pageSize"
                    layout="sizes, prev, pager, next"
                    :total="data.totalCount || 0">
                </el-pagination>
            </div>
        </div>

        <VideoPlay ref="videoPlay"></VideoPlay>
        <VideoEdit ref="videoEdit" @onUpdate="getDataList()"></VideoEdit>
        <VideoTask ref="videoTask"></VideoTask>
    </div>
</template>

<script>
import Tab from './components/tab';
import VideoPlay from "@/components/VideoPlay";
import VideoEdit from './components/video-edit';
import VideoTask from './components/video-task';
export default {
    components:{Tab,VideoPlay,VideoEdit,VideoTask},
    data(){
        return{
            data: null,
            dataList: null,
            params:{
                pageNo: 1,
                pageSize: 15,
            },
            listLoading: true,
        }
    },
    computed:{
        videoType(){
            return new Map([
                [1,'MV'],
                [2,'VLOG'],
                [3,'Live现场'],
                [4,'Fan拍'],
                [9,'其它']
            ])
        }
    },
    mounted(){
        this.getDataList();
    },
    methods:{
        //获取列表
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/video/getPageList",
                method: "POST",
                data: this.params,
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.data = result;
                        this.dataList = result.result;
                    } else {
                        this.$message.error(msg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pageNo = 1;
            this.params.pageSize = e;
            this.getDataList();
        },
        //播放视频
        play(data){
			this.$refs.videoPlay.show(data);          
        },
    }
}
</script>

<style lang="scss" scoped>

</style>