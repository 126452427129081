<template>
    <el-drawer
        title="待转码视频"
        :visible.sync="drawerShow"
        :wrapperClosable="false"
        :destroy-on-close="true"
        size="auto"
        @close="close"
    >
        <div class="drawer-content">
            <el-table
                :data="dataList"
                style="width: 100%"
                v-loading="listLoading"
                size="small"
                header-row-class-name="page-list-table"
            >
                <el-table-column prop="id" label="视频ID"></el-table-column>
                <el-table-column prop="name" label="视频名称" min-width="120" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createTime" label="上传时间" min-width="150"></el-table-column>
            </el-table>
            <div  class="page-pagination-box clearfix MT20">
                <el-pagination
                    v-if="data"
                    class="fr"
                    @size-change="handleSizeChange"
                    @current-change="getDataList"
                    :current-page.sync="params.pageNo"
                    :page-sizes="[15, 30, 50]"
                    :page-size.sync="params.pageSize"
                    layout="sizes, prev, pager, next"
                    :total="data.totalCount || 0">
                </el-pagination>
            </div>
        </div>
        <div class="drawer-footer">
            <el-button type="primary" @click="close">确定</el-button>
        </div>
    </el-drawer>
</template>

<script>
const defaultParams = {
    pageNo: 1,
    pageSize: 15,
}
export default {
    data(){
        return{
            drawerShow: false,
            data: null,
            dataList: null,
            params:{
                ...defaultParams
            },
            listLoading: true,
        }
    },
    mounted(){
        // this.getDataList();
    },
    methods:{
        close() {
            this.params = {...defaultParams};
            this.drawerShow = false;
        },
        show() {
            this.getDataList();
            this.drawerShow = true;
        },
        //获取列表
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/video/task/list",
                method: "POST",
                data: this.params,
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.data = result;
                        this.dataList = result.result;
                    } else {
                        this.$message.error(msg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pageNo = 1;
            this.params.pageSize = e;
            this.getDataList();
        },
        
    }
}
</script>

<style lang="scss" scoped>

</style>