<template>
    <el-drawer
        :title="id ? '编辑视频' : '创建视频' "
        :visible.sync="drawerShow"
        :wrapperClosable="false"
        :destroy-on-close="true"
        size="auto"
        @close="close"
    >
        <div class="drawer-content" v-loading="dataLoading">
            <el-form
                :model="formData"
                :rules="rules"
                ref="formData"
                label-position="top"
            >
                <!-- <el-row :gutter="30">
                    <el-col :xs="24" :md="12">
                        <el-form-item prop="display" label="状态:">
                            <el-radio-group v-model="formData.display" size="medium">
                                <el-radio :label="1">显示</el-radio>
                                <el-radio :label="0">隐藏</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :md="12">
                        <el-form-item prop="isSelected" label="设为精选视频:">
                            <el-radio-group v-model="formData.isSelected" size="medium">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="2">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row> -->
                <el-form-item prop="name" label="名称:">
                    <el-input v-model="formData.name" placeholder="请输入视频名称" size="medium" maxlength="40"></el-input>
                </el-form-item>
                <el-form-item prop="type" label="类型:">
                    <el-select v-model="formData.type" placeholder="请选择" size="medium">
                        <el-option label="MV" :value="1"></el-option>
                        <el-option label="Vlog" :value="2"></el-option>
                        <el-option label="Live现场" :value="3"></el-option>
                        <el-option label="Fan拍" :value="4"></el-option>
                        <el-option label="其他" :value="9"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="styles" label="风格:" >
                    <el-cascader
                        v-if="drawerShow"
                        v-model="styles"
                        :options="styleList"
                        filterable
                        :props="{multiple:true,label:'name',children:'tags',value:'id',emitPath:true,checkStrictly:true}"
                        size="medium"
                        style="width:100%"
                        @change="selectStyles">
                        <template slot-scope="{ node, data }">
                            <span>{{ data.name }}</span>
                            <span v-if="!node.isLeaf"> ({{ data.tags.length }}) </span>
                        </template>
                    </el-cascader>
                </el-form-item>
                <el-form-item prop="desc" label="描述:">
                    <el-input v-model="formData.desc" placeholder="请输入描述" size="medium" maxlength="100" autosize></el-input>
                </el-form-item>
                <el-form-item prop="pic" label="静态封面:" :show-message="formData.pic ? false : true">
                    <image-upload 
                            :url.sync="formData.pic" 
                            :urlFull.sync="formData.pic" 
                            :crop="true"
                            :width="1000"
                            :height="560"
                            ref="posterUpload"
                            class="poster-upload"
                        >
                            <span slot="size">建议宽高比16:9,规格为1000*560px</span>
                        </image-upload>
                </el-form-item>
                <!-- <el-form-item prop="useGif">
                    <span slot="label">
                        启用动态图: 
                        <small>
                            动态封面默认取视频前10秒自动生成，暂不支持修改
                            <el-button v-if="formData.gifUrl" icon="el-icon-view" size="mini" type="text" @click="gifView">点击预览</el-button>
                        </small>
                    </span>
                    <el-radio-group v-model="formData.useGif" size="medium">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item> -->
                <el-form-item prop="videoResourceId" label="文件:" :rules='[{ required: !id ? true : false, message: "请上传视频" }]' :show-message="videoResources ? false : true">
                    <template v-if="videoResources">{{videoResources.name}}</template>
                    <template v-if="!videoResources && formData.resourcesName">
                        {{formData.resourcesName}} 
                        <!-- <el-button icon="el-icon-download" @click="download" :loading="downLoading" size="mini" type="text">点击下载</el-button> -->
                    </template>
                    <div class="upload-btn MT10">
                        <el-upload
                            v-if="!progress || progress == 100"
                            class="upload-bar"
                            action
                            accept=".avi, .flv, .mkv, .mov, .mp4, .mpeg, .mpg, .rmvb, .ts, vob, .wmv, .m4v"
                            :before-upload="beforeVideoUpload"
                            :http-request="uploadFile"
                            :show-file-list="false"
                            :disabled="uploadDisabled"
                        >
                            <el-button slot="trigger" type="primary" :disabled="uploadDisabled" icon="el-icon-uploads">
                                上传视频
                            </el-button>
                        </el-upload>
                        <div v-else class="progress-bar">
                            <span class="progress" :style="'width:' + progress + '%'"></span>
                            <span>上传中({{ progress }}%)</span>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <div class="drawer-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="submitForm()" :loading="btnLoading"
                >提交</el-button
            >
        </div>

        <el-dialog :visible.sync="previewVisible" title="高清预览" center append-to-body>
            <div class="preview-box">
                <el-image :src="formData.gifUrl"></el-image>
            </div>
        </el-dialog>
    </el-drawer>
</template>

<script>
import {fetchAllTag,downloadFile, fileTask} from '@/assets/js/commonApi'
import ImageUpload from '@/components/ImageUpload';
import Upload from '@/assets/js/upload';

const defaultFormData = {
    // display:1,
    name:"",
    styles:[],
    tags:[],
    pic:"",
    // isSelected: 2,
    type:9,
    // useGif:0,
    upload:2,
    desc: "",
    videoResourceId:''
}
export default {
    components:{ImageUpload},
    data(){
        return{
            drawerShow: false,
            dataLoading: false,
            btnLoading:false,
            formData:{...defaultFormData},
            rules:{
                name: [{ required: true, message: "请输入视频名称" }],
                styles: [{ required: true, message: "请选择音乐风格" }],
                pic:[{ required: true, message: "请上传静态封面" }]
            },
            styles:[],
            styleList:[],//所有风格Tag集合
            styleArray:[],
            id:'',
            previewVisible: false,
            progress: null,
            uploadDisabled: false,
            videoResources:null,
            downLoading: false,
        }
    },
    created(){
        this.getStyleList();
    },
    methods:{
        //获取所有风格
        async getStyleList(){
            const res = await fetchAllTag({}).catch(() => {});
            const{ state, result} = res.data;
            if( state == 1 ){
                this.styleArray = result ? [...result] : [];
            }
        },
        close() {
            this.formData = {...defaultFormData};
            this.styles = [];
            this.styleList = [];
            this.videoResources = null;
            this.$refs['formData'].clearValidate();
            // this.$refs['formData'].resetFields();
            this.drawerShow = false;
        },
        show(id) {
            this.id = id;
            if( id ){
                this.getData(); 
            }else{
                this.styleList = [...this.styleArray];
            }
            this.drawerShow = true;
        },

        getData(){
            this.dataLoading = true;
            this.$request({
                url: '/video/getDetail',
                params: {id: this.id},
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1) {
                        this.formData = {
                            ...defaultFormData,
                            ...result,
                            styles: result.styles ? result.styles.split(',') : [],
                            tags : result.tags ? result.tags.split(',') : []
                        }
                        if(result && result.tagVOS){

                            result.tagVOS.map(item => {
                                const _arry = [];
                                _arry.push(item.styleId);

                                // this.formData.styles.push(item.styleId);
                                if(item.tagId){
                                    // this.formData.tags.push(item.tagId);
                                    _arry.push(item.tagId);
                                }
                                
                                this.styles.push(_arry);
                            })
                            this.styles = Array.from(this.styles).sort((x,y) => x[0] - y[0]);
                        }
                        this.styleList = [...this.styleArray];
                        
                    } else {
                        this.$message.error(msg || "获取详情失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("获取详情失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                });   
        },

        //选取风格
        selectStyles(){
            // 风格&tag id组装
            let _styles = [], _tags = [];
            this.styles.forEach( item =>  {
                _styles.push( item[0] );
                if(item[1]){
                    _tags.push( item[1] ); 
                }
            })

            this.formData.styles = _styles ? [...new Set(_styles)] : [];
            this.formData.tags = _tags ? [...new Set(_tags)] : [];
        },
        
        // 表单提交
        submitForm() {
            let self = this;
            self.$refs["formData"].validate((valid) => {
                if (valid) {
                    this.submitAction()
                } else {
                    return false;
                }
            });
        },
        submitAction(){
            const _url = "/video/save";
            this.btnLoading = true;
            this.$request({
                url: _url,
                data: {
                    ...this.formData,
                    styles: this.formData.styles.toString(),
                    tags: this.formData.tags.toString(),
                    videoResourceId: this.videoResources && this.videoResources.id || '',
                    upload: this.videoResources && this.id ? 1 : 0
                },
                method: "post",
            })
                .then((res) => {
                    const { state, msg } = res.data;
                    if (state == 1) {
                        this.$emit('onUpdate');
                        this.close();
                    } else {
                        this.$message.error(msg || "操作失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("操作失败,请重试");
                })
                .finally(() => {
                    this.btnLoading = false;
                });   
        },
        gifView(){
            this.previewVisible = true
        },
        //下载文件
        async download(){
            this.downLoading = true;
            const _url = this.formData.playUrl;
            // await downloadFile(_url,this.formData.name+'.'+this.$utils.getFileType(_url));
            await downloadFile(_url,_url.substring(_url.lastIndexOf('/')+1))
            this.downLoading = false;
        },
        beforeVideoUpload(file) {
            const _typeArry = ['.avi', '.flv', '.mkv', '.mov', '.mp4', '.mpeg', '.mpg', '.rmvb', '.ts', '.vob', '.wmv', '.m4v'];
            const _fileType = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            const isTrueType = _typeArry.find(v => {
                return v == _fileType;
            });
            if (!isTrueType) {
                this.$message.error('视频文件格式错误,请重新选择');
            }
            return isTrueType ? true : false;
        },
        //上传至腾讯云
        uploadFile(req) {
            this.uploadDisabled = true;
            this.videoUploadReady(req.file, res => {
                if (res && res.data && res.data.state && res.data.result) {
                    Upload.sliceUploadFile({
                        taskKey: this.formData.id,
                        data: req.file,
                        // onTaskReady: data => {
                        //     this.$emit('onTaskReady', data);
                        // },
                        //上传进度
                        onProgress: progressData => {
                            this.progress = parseInt(progressData.percent * 100);
                        },
                        //上传成功
                        onSuccess: data => {
                            //这里需要调用java接口 传data以及Id 做数据关联
                            //this.$emit('onSuccess', data);
                            this.videoUploadCallBack(data, res.data.result);
                            this.uploadDisabled = false;
                        },
                        //上传失败
                        onError: () => {
                            this.progress = null;
                            this.uploadDisabled = false;
                        }
                    });
                } else {
                    this.uploadDisabled = false;
                }
            });
        },
        //视频准备上传
        async videoUploadReady(data, callback) {

            this.$confirm("新上传的视频需要等待转码后才能正式使用<br/>你可以在【待转码视频】列表中看到对应的视频", '提示', {
                type: 'warning',
                // center: true,
                dangerouslyUseHTMLString: true,
                closeOnClickModal: false
            }).then(async () => {
                this.btnLoading = true;    
                const res = await this.$request({
                    url: '/video/save/resource',
                    params: {
                        fileName: data.name
                    },
                    method: "post",
                }).catch(() => {
                    this.$message.error("操作失败,请重试");
                }).finally(() => {
                    this.btnLoading = false;
                });   
                if (res && res.data) {
                    if (res.data.state == 1) {
                        this.videoResources = res.data.result;
                        this.formData.videoResourceId =  this.videoResources.id
                    } else {
                        this.$message({message: res.data.msg, type: 'error'});
                        throw new Error(res.data.msg || '操作失败,请重试');
                    }
                }
                callback(res);
            }).catch(() => {})
        },
        //视频上传成功
        videoUploadCallBack(data1,data2){
            const _data = {
                bucket: data1.Bucket,
                key: data1.Key,
                signUrl: data1.url,
                resourceId: data2.id
            }
            fileTask(_data)
                .then(res => {
                    if (!res.data.state) {
                        this.$message({message: res.data.msg, type: 'error'});
                        throw new Error(res.data.msg || res.data.code);
                    }
                })
                .finally(() => {});
        }
    }
}
</script>

<style lang="scss" scoped>
.preview-box{
    text-align: center;
    .el-image{
        max-width: 100%;
    }
}
.upload-btn {
    height: 36px;
    line-height: 36px;
    border-radius: 4px;
    text-align: center;
    position: relative;
    background: $--color-theme;
    overflow: hidden;
    .upload-bar{
        ::v-deep .el-upload{
            width: 100%;
        }
    }
    .el-button {
        width: 100%;
        background: none;
        border: 0;
    }
    .progress-bar {
        display: block;
        height: 100%;
        color: #ffffff;
        span {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
        }
        .progress {
            height: 100%;
            background: #67c23a;
            transition: width 0.6s ease-out;
        }
    }
}
</style>